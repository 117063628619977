import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['principal', 'years', 'interestRate', 'downPayment', 'result'];

  connect() {
    this.principalTarget.oninput = this.calculate;
    this.yearsTarget.oninput = this.calculate;
    this.interestRateTarget.oninput = this.calculate;
    this.downPaymentTarget.oninput = this.calculate;
  }

  calculate = (event) => {
    event.preventDefault();

    const principal = (this.principal - (this.principal * this.downPayment));
    const rate = ((this.monthlyRate * (1 + this.monthlyRate) ** this.months)
      / (((1 + this.monthlyRate) ** this.months) - 1));
    const total = principal * (rate);

    if (total) {
      this.resultTarget.value = total.toFixed(2);
    }
  }

  get principal() {
    return this.valueFor('principal');
  }

  get years() {
    return this.valueFor('years') || 1;
  }

  get months() {
    return this.years * 12;
  }

  get monthlyRate() {
    return this.interestRate / 12;
  }

  get interestRate() {
    return this.valueFor('interestRate') / 100;
  }

  get downPayment() {
    return this.valueFor('downPayment') / 100;
  }

  valueFor(targetName) {
    return parseFloat(this[`${targetName}Target`].value);
  }
}
