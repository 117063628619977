import { Controller } from 'stimulus';

export default class extends Controller {
  submit(event) {
    event.preventDefault();

    fetch(this.element.action, {
      method: 'POST',
      body: new FormData(this.element),
    }).then(() => {
      alert('Your message was sent!'); // eslint-disable-line no-alert
      this.element.reset();
    });
  }
}
