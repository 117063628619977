import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['principal', 'addition', 'years', 'interestRate', 'compoundCount', 'result'];

  connect() {
    this.principalTarget.oninput = this.calculate;
    this.additionTarget.oninput = this.calculate;
    this.yearsTarget.oninput = this.calculate;
    this.interestRateTarget.oninput = this.calculate;
    this.compoundCountTarget.oninput = this.calculate;
  }

  calculate = (event) => {
    event.preventDefault();

    let total = this.principal;
    for (let i = 0; i < this.years; i += 1) {
      for (let j = 0; j < this.compoundCount; j += 1) {
        total += this.addition / this.compoundCount;
        total *= (1 + (this.interestRate / this.compoundCount));
      }
    }

    if (total) {
      this.resultTarget.value = total.toFixed(2);
    }
  }

  get principal() {
    return this.valueFor('principal');
  }

  get addition() {
    return this.valueFor('addition') || 0.0;
  }

  get years() {
    return this.valueFor('years') || 1;
  }

  get interestRate() {
    return this.valueFor('interestRate') / 100;
  }

  get compoundCount() {
    return this.valueFor('compoundCount') || 1;
  }

  valueFor(targetName) {
    return parseFloat(this[`${targetName}Target`].value);
  }
}
